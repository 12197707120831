export const popularSearchesData = [
  {
    label: 'England',
    to: { hierarchyPath: 'England/', category: 'campsites' },
    imgUrl: require('@/assets/popular-searches/england.jpg'),
    displayOrder: 1,
  },
  {
    label: 'Wales',
    to: { hierarchyPath: 'Wales/', category: 'campsites' },
    imgUrl: require('@/assets/popular-searches/wales.jpg'),
    displayOrder: 2,
    isLCP: true,
  },
  {
    label: 'Lake District',
    to: {
      hierarchyPath: 'England/North_West/Cumbria/',
      category: 'campsites',
    },
    imgUrl: require('@/assets/popular-searches/lake-district.jpg'),
    displayOrder: 3,
  },
  {
    label: 'Campsites with electric hook up',
    to: { facet: 'electric', category: 'campsites' },
    imgUrl: require('@/assets/popular-searches/electric.jpg'),
    displayOrder: 9,
  },
  {
    label: 'Cornwall',
    to: {
      hierarchyPath: 'England/South_West/Cornwall/',
      category: 'campsites',
    },
    imgUrl: require('@/assets/popular-searches/cornwall.jpg'),
    displayOrder: 10,
  },
  {
    label: 'Scotland',
    to: { hierarchyPath: 'Scotland/', category: 'campsites' },
    imgUrl: require('@/assets/popular-searches/scotland.jpg'),
    displayOrder: 11,
  },
  {
    label: 'Campsites by the beach',
    to: { facet: 'beaches', category: 'campsites' },
    imgUrl: require('@/assets/popular-searches/beach.jpg'),
    displayOrder: 4,
  },
  {
    label: 'North Yorkshire',
    to: {
      hierarchyPath: 'England/North_East/North_Yorkshire/',
      category: 'campsites',
    },
    imgUrl: require('@/assets/popular-searches/north-yorkshire.jpg'),
    displayOrder: 5,
  },
  {
    label: 'Devon',
    to: { hierarchyPath: 'England/South_West/Devon/', category: 'campsites' },
    imgUrl: require('@/assets/popular-searches/devon.jpg'),
    displayOrder: 6,
  },
  {
    label: 'Glamping with hot tub',
    to: { facet: 'hot-tub', category: 'glamping' },
    imgUrl: require('@/assets/popular-searches/hot-tub.jpg'),
    displayOrder: 12,
  },
  {
    label: 'Dorset',
    to: {
      hierarchyPath: 'England/South_West/Dorset/',
      category: 'campsites',
    },
    imgUrl: require('@/assets/popular-searches/dorset.jpg'),
    displayOrder: 13,
  },
  {
    label: 'Wild camping',
    to: { facet: 'wild-camping', category: 'campsites' },
    imgUrl: require('@/assets/popular-searches/wild-camping.jpg'),
    displayOrder: 14,
  },
  {
    label: 'Camping near me',
    to: {
      hierarchyPath: 'near-me/',
      category: 'campsites',
    },
    imgUrl: require('@/assets/popular-searches/near-me.jpg'),
    displayOrder: 7,
  },
  {
    label: 'Dog friendly campsites',
    to: { facet: 'dogs-allowed', category: 'campsites' },
    imgUrl: require('@/assets/popular-searches/dog-friendly.jpg'),
    displayOrder: 8,
  },
  {
    label: 'Glamping',
    to: {
      category: 'glamping',
    },
    imgUrl: require('@/assets/popular-searches/glamping.jpg'),
    displayOrder: 15,
  },
]
